import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Section, Container, BoxContainer, Box, Title, Text, ImageContainer } from './styles'
import { MothersDayHeader } from '../EffortlessToUse/styles'
import { CenteredContent, BuyNowLink } from '../Hero/styles'
import { FRAME_2_BUY_LINK } from '../../utils/constants'

const SnapFrame = ({ page }) => {
  const [selectedFrame, setSelectedFrame] = useState('style')
  const [isMobile, setIsMobile] = useState(false)
  const imageContainerRef = useRef(null)

  const updateIsMobile = () => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 576)
    }
  }

  useEffect(() => {
    updateIsMobile()
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateIsMobile)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', updateIsMobile)
      }
    }
  }, [])

  const {
    style,
    magnets,
    material,
    bundle,
    styleMobile,
    magnetsMobile,
    materialMobile,
    bundleMobile,
  } = useStaticQuery(graphql`
    query SnapFrameQuery {
      style: file(relativePath: { eq: "frame2/snap-frame-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      magnets: file(relativePath: { eq: "frame2/snap-frame-magnets.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      material: file(relativePath: { eq: "frame2/snap-frame-material.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      bundle: file(relativePath: { eq: "frame2/snap-frame-bundle.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      styleMobile: file(relativePath: { eq: "frame2/snap-frame-style-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      magnetsMobile: file(relativePath: { eq: "frame2/snap-frame-magnets-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      materialMobile: file(relativePath: { eq: "frame2/snap-frame-material-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      bundleMobile: file(relativePath: { eq: "frame2/snap-frame-bundle-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const images = {
    style,
    material,
    magnets,
    bundle,
  }

  const imagesMobile = {
    style: styleMobile,
    material: materialMobile,
    magnets: magnetsMobile,
    bundle: bundleMobile,
  }

  const productData = [
    {
      id: 'style',
      title: 'A Match For Your Style',
      description:
        'So many options, so many materials—there’s a perfect fit for every home. Swap in seconds for instant redecoration.',
    },
    {
      id: 'material',
      title: 'Gallery-Ready Materials',
      description:
        'Choose from real hardwood, modern aluminum, and colorful classic Snap Frames. Every style is ready for its closeup.',
    },
    {
      id: 'magnets',
      title: 'Magnetic Personality',
      description:
        'Change your Snap Frame in seconds, thanks to small-but-mighty magnets embedded safely out of sight.',
    },
    {
      id: 'bundle',
      title: 'Bundle & Save',
      description: 'Why choose just one? Save when you buy extra Snap Frames with your Frame 2.',
    },
  ]
  const handleTileClick = (productId) => {
    setSelectedFrame(productId)
    if (isMobile && imageContainerRef.current) {
      imageContainerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <Section lessPadding={page === 'snap'}>
      <MothersDayHeader>Snap Frame Design</MothersDayHeader>
      <Container ref={imageContainerRef}>
        <BoxContainer>
          {productData.map((product) => (
            <Box
              key={product.id}
              selected={selectedFrame === product.id}
              onClick={() => {
                setSelectedFrame(product.id)
                handleTileClick(product.id)
              }}
            >
              <Title>{product.title}</Title>
              <Text>{product.description}</Text>
              {product.id === 'bundle' && <Text> Savings applied at checkout.</Text>}
            </Box>
          ))}
        </BoxContainer>
        <ImageContainer>
          <GatsbyImage
            image={
              isMobile
                ? imagesMobile[selectedFrame].childImageSharp.gatsbyImageData
                : images[selectedFrame].childImageSharp.gatsbyImageData
            }
            alt={selectedFrame}
            style={{ width: '100%' }}
          />
        </ImageContainer>
      </Container>
      <CenteredContent hide={page === 'snap'}>
        <BuyNowLink to={FRAME_2_BUY_LINK}>Buy Now</BuyNowLink>
      </CenteredContent>
    </Section>
  )
}

export default SnapFrame

SnapFrame.propTypes = {
  page: PropTypes.string,
}
