import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'

const GallerySlider = () => {
  const { walnut, aluminum, cover, black, sage } = useStaticQuery(graphql`
    query GalleryImagesQuery {
      walnut: file(relativePath: { eq: "frame2/gallery-walnut.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      aluminum: file(relativePath: { eq: "frame2/gallery-aluminum.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cover: file(relativePath: { eq: "frame2/gallery-black-cover.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      black: file(relativePath: { eq: "frame2/gallery-black.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      sage: file(relativePath: { eq: "frame2/gallery-sage.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const images = [walnut, black, aluminum, sage, cover]

  return (
    <div>
      <Slider
        infinite
        speed={500}
        slidesToShow={2}
        slidesToScroll={1}
        centerPadding="10%"
        autoplay
        centerMode
        autoplaySpeed={4000}
        arrows
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.1,
              centerMode: false,
            },
          },
        ]}
      >
        {images.map((image, index) => (
          <div key={index}>
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={`Gallery Image ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default GallerySlider
