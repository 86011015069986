import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) => props.bgColor || props.theme.blueLight};
`
export const Container = styled.div`
  width: 90%;
  margin: auto;
  padding: 60px 0px;
  @media (max-width: ${breakpoints.m}px) {
    padding: 50px 0px 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 50px 0px 20px;
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.m}px) {
    flex-wrap: none;
  }
`
export const Card = styled.div`
  margin: 0px 24px;
  width: 320px;
  align-items: center;
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px 16px;
    width: 280px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    margin: 30px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 30px 5px;
  }
`
export const MothersDayHeader = styled.h2`
  color: ${(props) => props.headerColor || props.theme.blueDark};
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.19px;
  text-align: center;
  margin: 0px;
  max-width: ${(props) => props.maxWidth || '100%'};
  margin: auto;
  span {
    font-family: 'P22MackinacProBold';
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
  @media (max-width: 360px) {
    font-size: 26px;
  }
`

export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: ${(props) => props.theme.grayDark};
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    text-align: left;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    text-align: left;
  }
`
export const ImageContainer = styled.div`
  width: 100px;
  margin: auto;
  margin-top: 50px;
  .gatsby-image-wrapper picture img {
    object-fit: fill !important;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 80px;
    margin: 20px auto 50px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 0px 50px 0px;
  }
  @media (max-width: 400px) {
    margin: ${(props) => (props.short ? '10px 0px 30px 0px' : '40px 0px 50px 0px')};
  }
  @media (max-width: 380px) {
    margin: ${(props) => (props.short ? '10px 0px 30px 0px' : '40px 0px 60px 0px')};
  }
  @media (max-width: 360px) {
    margin: ${(props) => (props.short ? '10px 0px 30px 0px' : '60px 0px 50px 0px')};
  }
`
export const IconContainer = styled.div`
  width: 32px;
  margin: 30px auto;
  @media (max-width: ${breakpoints.m}px) {
    width: 24px;
    margin: 0px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 24px;
    margin: 15px 0px 20px 0px;
  }
`
export const ColumnLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    width: 40%;
  }
  @media (max-width: ${breakpoints.s}px) {
    display: block;
    width: 30%;
  }
`
export const ColumnRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    width: 60%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 70%;
  }
  @media (max-width: 380px) {
    width: 65%;
  }
`

export const SubHeader = styled.p`
  font-family: 'P22MackinacProBook';
  text-align: center;
  font-size: 24px;
  color: '#444444';
  padding-top: 50px;
  @media (max-width: ${breakpoints.m}px) {
    padding-top: 20px;
    font-size: 18px;
  }
`
