import React from 'react'

import { Header } from 'src/utils/styles/global-styles'
import {
  Section,
  Container,
  Table,
  TableHeader,
  TableRow,
  FeatureName,
  FeatureDetail,
} from './styles'

import { FRAME_DATA } from './constants'

const FrameTable = () => (
  <Section>
    <Container>
      <Header>Find Your Perfect Frame</Header>
      <Table>
        <thead>
          <tr>
            <th />
            <TableHeader>
              Frame <span>(Size 10", 15")</span>
            </TableHeader>
            <TableHeader>
              Frame 2 <span>(Size 10")</span>
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {FRAME_DATA.map(({ feature, frame, frame2 }, i) => (
            <TableRow light={i % 2 === 0} key={feature}>
              <FeatureName>{feature}</FeatureName>
              <FeatureDetail>{frame}</FeatureDetail>
              <FeatureDetail>{frame2}</FeatureDetail>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  </Section>
)

export default FrameTable
