import React from 'react'
import { Section, Container, Header, Text } from './styles'

const FreeReturns = () => (
  <Section>
    <Container>
      <Header>120-day free returns</Header>
      <Text>
        Our products are designed to delight. If you buy a Skylight and decide it's not for you,
        return it within 120 days for a full refund.
      </Text>
    </Container>
  </Section>
)

export default FreeReturns
