import { BUY_LINK, CAL_BUY_LINK, FRAME_2_BUY_LINK } from '../../utils/constants'

export const HOMEPAGE_PRODUCT_DETAILS = [
  {
    name: 'Home',
    preheading: 'Buy the #1 Gift of the Season',
    heading: 'Black Friday is Here!',
    subheading:
      'Bring home the BEST deal of the year for Calendars and Frames. The perfect gift at the perfect price!',
    link: `${BUY_LINK}`,
    link2: `${CAL_BUY_LINK}`,
    ctaText: 'Shop Frame',
    ctaText2: 'Shop Calendar',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Homepage CTA',
  },
]

export const FRAME_PRODUCT_DETAILS = [
  {
    name: 'Frame 2',
    preheading: 'Just released: the new Frame 2',
    heading: 'Change the way you share memories.',
    subheading: `Our best-selling smart photo frame just got even better. And with our new Snap Frames, you don't have to choose a color - you can have them all.`,
    link: `${FRAME_2_BUY_LINK}`,
    ctaText: 'Buy Now',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Frame 2 CTA',
  },
]
