import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  padding: ${(props) => (props.lessPadding ? '80px 0px 0px' : '80px 0px')};
  margin-top: -10px;
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.lessPadding ? '50px 0px 20px' : '50px 0px')};
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 56px 0px;
  justify-content: center;
  margin: auto;
  max-width: 90%;
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 32px 0px;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 40px;
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding-right: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`

export const Box = styled.div`
  border: 1px solid #bdbdbd;
  padding: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#fff' : props.theme.blueLight)};
  border-radius: 10px;
  width: 340px;
  &:hover {
    background-color: #fff;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 20px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 45%;
    margin: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin: 10px 0px;
  }
`

export const ImageContainer = styled.div`
  width: 60%;
  max-width: 750px;
  img {
    border-radius: 25px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const Title = styled.h3`
  font-family: 'FilsonPro';
  font-size: 18px;
  letter-spacing: 0.2px;
  margin: 0px;
`
export const Text = styled.p`
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.2px;
  margin-bottom: 0px;
`
