import React, { Component } from 'react'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import { CAL_TESTIMONIALS, FRAME_TESTIMONIALS } from './constants'
import {
  CustomerDetail,
  DetailsContainer,
  Text,
  Card,
  Arrow,
  LogoContainer,
  Container,
  SliderContainer,
  LeftArrow,
  RightArrow,
  ReviewerDetails,
  SocialText,
} from './styles'

function TestimonialCard(props) {
  const { text, image, name, location, household, frame } = props

  return (
    <Card id="card">
      <Text className="text">
        <SocialText id="social">
          <Trans>{text}</Trans>
        </SocialText>
      </Text>

      <ReviewerDetails>
        {frame ? (
          <CustomerDetail id="customer">
            <Trans>{name}</Trans>
          </CustomerDetail>
        ) : (
          <>
            <LogoContainer id="logo">
              <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="Happy Customer" />
            </LogoContainer>
            <DetailsContainer>
              <CustomerDetail id="customer">
                <Trans>{name}</Trans>
              </CustomerDetail>
              <CustomerDetail id="customer">
                <Trans>
                  {household} {location}
                </Trans>
              </CustomerDetail>
            </DetailsContainer>
          </>
        )}
      </ReviewerDetails>
    </Card>
  )
}
class RedesignCalendarCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const { backImg, forwardImg, images, frame } = this.props
    const testimonials = frame ? FRAME_TESTIMONIALS : CAL_TESTIMONIALS
    return (
      <SliderContainer>
        <LeftArrow onClick={this.previous}>
          <Arrow image={backImg.childImageSharp.gatsbyImageData} alt="Back Arrow" />
        </LeftArrow>
        <Slider
          ref={(c) => (this.slider = c)}
          className="center"
          centerMode
          infinite
          // centerPadding="60px"
          slidesToShow={3}
          speed={500}
          slidesToScroll={1}
          initialSlide={0}
          dots
          responsive={[
            {
              breakpoint: 1100,
              settings: {
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: '300px',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 992,
              settings: {
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: '200px',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 740,
              settings: {
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: '60px',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 450,
              settings: {
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1,
              },
            },
          ]}
        >
          {testimonials.map((review, i) => (
            <React.Fragment key={i}>
              <Container>
                <TestimonialCard
                  title={review.title}
                  text={review.text}
                  image={images[i]}
                  name={review.name}
                  location={review.location}
                  household={review.household}
                  frame={frame}
                />
              </Container>
            </React.Fragment>
          ))}
        </Slider>
        <RightArrow onClick={this.next}>
          <Arrow image={forwardImg.childImageSharp.gatsbyImageData} alt="Forward Arrow" />
        </RightArrow>
      </SliderContainer>
    )
  }
}
TestimonialCard.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.any,
  location: PropTypes.string,
  household: PropTypes.string,
  frame: PropTypes.bool,
}

RedesignCalendarCarousel.propTypes = {
  forwardImg: PropTypes.any,
  backImg: PropTypes.any,
  images: PropTypes.array,
  frame: PropTypes.bool,
}
export default RedesignCalendarCarousel
