export const CAL_TESTIMONIALS = [
  {
    text: `“Skylight Calendar has reduced my son's anxiety so much that he was discharged by his psychologist...it has changed our life."`,
    name: 'Mary M.',
    household: 'Mom of 1,',
    location: 'Perkasie, PA',
  },
  {
    text: `“This has been a game changer! It has allowed our family a one-stop shop for all things calendar and keeps my husband and I on the same page which is huge!”`,
    name: 'Lucy G.',
    household: 'Mom of 2,',
    location: 'Brooklyn, NY',
  },
  {
    text: `“I LOVE that my brain is no longer the long-term storage plan for our family's busy schedule, and my kids have never been so motivated by a chore chart.”`,
    name: 'Emily B.',
    household: 'Mom of 3,',
    location: 'Los Angeles, CA',
  },
  {
    text: `“Skylight has brought clarity and structure to our busy schedules. It's a great communication tool for our whole family to get on the same page.”`,
    name: 'Mark S.',
    household: 'Dad of 2,',
    location: 'Dallas, TX',
  },
  {
    text: `“I love how it takes so much off of my mental load and how easy it is for my kids to use.”`,
    name: 'Krista W.',
    household: 'Mom of 3,',
    location: 'Phoenix, AZ',
  },
]

export const FRAME_TESTIMONIALS = [
  {
    text: `"I love how easy the device is to set up, and the packaging was really impressive. The overall look, especially the white frame, is stunning. Plus, the option to switch out the frames is a feature I really appreciate!"`,
    name: 'Alex Mitchell',
  },
  {
    text: `"The frame is sleek and beautifully designed. I got the walnut trim, which blends perfectly with my home décor. It feels solid and well-built, with a good weight that isn't too heavy. The walnut frame is secure but easy to remove if you want to swap styles. I’m really impressed so far!"`,
    name: 'Megal Lawson',
  },
  {
    text: `"The packaging is stylish and very secure. The glass and matte finish give the frame a true picture frame look, making it feel more like a traditional photo display than a tech device."`,
    name: 'David Harris',
  },
  {
    text: `The frame itself has a sleek, modern design, and I really like the width of the black border. Since it's magnetic, I'm excited to explore other color options to match my changing home décor."`,
    name: 'Rachel Morgan',
  },
  {
    text: `"I love the snap-on frame feature! Being able to swap it out to match my décor or personal style as it evolves is a game-changer."`,
    name: 'Emily Stevens',
  },
]
