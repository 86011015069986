export const FRAME_DATA = [
  {
    feature: 'Display',
    frame: '10" 1280x800, 15" 1920x1080',
    frame2: '1920x1200',
  },
  { feature: 'Styles', frame: 'One Style, Four Colors', frame2: 'Four Styles, Eight Colors' },
  { feature: 'Auto Brightness Adjustment', frame: 'No', frame2: 'Yes' },
  { feature: 'Free Captions and Videos', frame: 'No', frame2: 'Yes' },
  {
    feature: 'Speed and Storage',
    frame: '1.5 GHz Quad-Core, 10" 8GB, 15" 16GB',
    frame2: '2GHz Quad-Core, 16 GB',
  },
  { feature: 'Price', frame: '$159.99 - $299.99', frame2: '$169.99 - $199.99' },
]
