import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'

import {
  Section,
  Container,
  Row,
  Card,
  Text,
  ImageContainer,
  IconContainer,
  ColumnLeft,
  ColumnRight,
  SubHeader,
  MothersDayHeader,
} from './styles'

import { ENGLISH_SPEAKING_COUNTRY } from '../../utils/constants'

const EffortlessToUse = ({ page, title, bold, bgColor, headerColor }) => {
  const { t } = useTranslation('common')

  const { plugImg, mailImg, heartImg, step1Icon, step2Icon, step3Icon } = useStaticQuery(graphql`
    query EffortlessToUseQuery {
      plugImg: file(relativePath: { eq: "effortlessToUse/plug-blue.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      mailImg: file(relativePath: { eq: "effortlessToUse/mail-blue.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      heartImg: file(relativePath: { eq: "effortlessToUse/heart-blue.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      step1Icon: file(relativePath: { eq: "effortlessToUse/step-1-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      step2Icon: file(relativePath: { eq: "effortlessToUse/step-2-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      step3Icon: file(relativePath: { eq: "effortlessToUse/step-3-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const defaultImages = [
    { name: plugImg, alt: 'Plug Into Outlet' },
    { name: mailImg, alt: 'Envelope' },
    { name: heartImg, alt: 'Heart' },
  ]
  const images = defaultImages
  const descriptions = [
    {
      icon: step1Icon,
      alt: '1 Icon',
      text: t('Plug in and use touchscreen to connect to WiFi'),
      calendar: t('Plug in and connect to Wi-Fi'),
    },
    {
      icon: step2Icon,
      alt: '2 Icon',
      text: t('Pick a unique Skylight email address for your frame.'),
      calendar: t('Sync your existing calendars or invite individual events'),
    },
    {
      icon: step3Icon,
      alt: '3 Icon',
      text: t(`Send photos to the frame by app or email and they will appear instantly!`),
      calendar: t(
        'Customize your display with color-coding and different views (day, week, or month!)'
      ),
    },
  ]

  return (
    <Section bgColor={bgColor}>
      <Container>
        <MothersDayHeader
          headerColor={headerColor}
          maxWidth={page === 'calendar' ? '480px' : '100%'}
        >
          <span>{bold}</span>
          {title || t('Delightfully Effortless To Use')}
        </MothersDayHeader>
        {page === 'calendar' && <SubHeader>All you've got to do is:</SubHeader>}
        <Row>
          <ColumnLeft>
            {images.map((image) => (
              <Card key={image.alt}>
                <ImageContainer short={ENGLISH_SPEAKING_COUNTRY}>
                  <GatsbyImage image={image.name.childImageSharp.gatsbyImageData} alt={image.alt} />
                </ImageContainer>
              </Card>
            ))}
          </ColumnLeft>
          <ColumnRight>
            {descriptions.map((description) => (
              <Card key={description.alt}>
                <IconContainer>
                  <GatsbyImage
                    image={description.icon.childImageSharp.gatsbyImageData}
                    alt={description.alt}
                  />
                </IconContainer>
                <Text>{description[page] || description.text}</Text>
              </Card>
            ))}
          </ColumnRight>
        </Row>
      </Container>
    </Section>
  )
}

EffortlessToUse.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  bold: PropTypes.string,
  bgColor: PropTypes.string,
  headerColor: PropTypes.string,
}

export default EffortlessToUse
