import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) => props.theme.orange};
  padding: 75px 0px;
  margin: 0px;
  position: relative;
  z-index: 2;
  @media (max-width: ${breakpoints.l}px) {
    padding: 40px 0px;
  }
`
export const Container = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  > * {
    width: 50%;
  }
  max-width: 1100px;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    text-align: center;
    > * {
      width: 100%;
    }
  }
`
export const Header = styled.h1`
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    text-align: center;
    > * {
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
  }
`

export const Text = styled.p`
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    margin-top: 10px;
  }
`
