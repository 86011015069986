import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const PressContainer = styled.div`
  max-width: 1020px;
  padding: ${(props) => (props.frame2 ? '0px 0 65px' : '40px 0 65px')};
  margin: 0 auto;
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.frame2 ? '8px 0px 40px' : '40px 0px')};
  }
`

export const PressRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-basis: 100%;
  @media (max-width: 800px) {
    display: block;
    text-align: center;
    img {
      margin-bottom: 60px;
    }
  }
`
export const logoDividerCss = css`
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    width: 75%;
  }
`

export const RowTopOnMobile = styled.div`
  ${logoDividerCss}
  > * {
    margin-right: 4rem;
  }
  @media (max-width: 800px) {
    margin-bottom: 2rem;
    > * {
      margin: 0 0.5rem 1rem;
    }
    > :first-child {
      margin-right: 4rem;
    }
  }
`

export const RowBottomOnMobile = styled.div`
  ${logoDividerCss}
  > :first-child {
    margin-right: 4rem;
  }
  @media (max-width: 800px) {
    > :first-child {
      margin: 0;
      margin-right: 4rem;
    }
  }
`

export const LogoContainer = styled.div`
  width: 70px;
  margin: 0px 24px;
  @media (max-width: ${breakpoints.s}px) {
    width: 44px;
    margin: 0px 10px;
  }
`

export const PressGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
  row-gap: 10px;
  #large {
    width: 120px;
  }
  #xl {
    width: 160px;
  }
  @media (max-width: ${breakpoints.s}px) {
    #large {
      width: 90px;
    }
    #xl {
      width: 110px;
    }
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  #large {
    width: 120px;
  }
  #xl {
    width: 160px;
  }
  @media (max-width: ${breakpoints.s}px) {
    #large {
      width: 90px;
    }
    #xl {
      width: 110px;
    }
  }
`
export const Header = styled.h2`
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.09px;
  text-align: center;
  color: ${(props) => props.theme.grayDark};
  border-bottom: solid 1.5px ${(props) => props.theme.coral};
  display: inline;
`
export const Center = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  margin-top: 40px;
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 0px;
    margin-top: 24px;
  }
`
