import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) => props.theme.orange};
`

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 72px 0px;
  @media (max-width: 1400px) {
    max-width: 90%;
  }
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`
const TableDateStyles = css`
  padding: 16px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 14px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 11px;
    padding: 8px;
  }
  @media (max-width: 400px) {
    padding: 4px;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
`
export const TableHeader = styled.th`
  ${TableDateStyles}
  padding: 8px;
  font-family: 'FilsonProBold';
  span {
    font-family: 'FilsonProBook';
  }
  @media (max-width: ${breakpoints.l}px) {
    span {
      display: block;
      font-size: 11px;
    }
  }
`
export const TableRow = styled.tr`
  background-color: ${(props) => (props.light ? props.theme.orange : '#FFEFD6')};
  border-bottom: 1px dashed ${(props) => props.theme.darkGray};
  &:last-child {
    border-bottom: none;
  }
`
export const FeatureName = styled.td`
  ${TableDateStyles}
  font-family: 'FilsonProBold';
  text-align: left;
`
export const FeatureDetail = styled.td`
  ${TableDateStyles}
  text-align: center;
  font-size: 14px;
  border-left: 1px dashed ${(props) => props.theme.darkGray};
`
